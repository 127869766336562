.loginView {
  height: 100vh;
  display: flex;
  justify-content: center;
  background: #2d3a4b;
  padding-top: 25vh;
}
.loginForm {
  width: 300px;
  .title {
    text-align: center;
    color: #fff;
  }
  .inputBox {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    input {
      color: #fff;
      background-color: transparent;
    }
    input:-webkit-autofill,input:-webkit-autofill:focus { 
      -webkit-box-shadow: 0 0 0px 1000px #293443 inset !important;
      text-shadow: 0px 0px 0px #fff;
      -webkit-text-fill-color: transparent;
    }
  }
  .loginFormBtn {
    width: 100%;
  }
}
