.mainSider {
  height: 100vh;
}
.mainHeader {
  padding: 0 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .balanceBox {
    flex-grow: 1;
    text-align: right;
  }
}
.mainContent {
  padding: 30px;
  box-sizing: border-box;
  height: calc(100vh - 64px);
  overflow: auto;
}